import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import './Map.css';

//mapboxgl.workerClass = require('worker-loader mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken =
  'pk.eyJ1IjoibWFkbWFkYSIsImEiOiJjbTN1NjhhdzcwYXdsMmtzZnJwNDF3aHY2In0.EqZzkVsl88iwszjeHlZTvA';

export const Map = () => {
  const mapContainerRef = useRef(null);

  const [lng, setLng] = useState(17.71169444);
  const [lat, setLat] = useState(54.75422222);
  const [zoom, setZoom] = useState(7);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/light-v11',
      center: [lng, lat],
      zoom: zoom
    });

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    map.on('move', () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });

map.on('load', () => {
      map.addSource('maine', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            // These coordinates outline Maine.
            coordinates: [
            [
              [
                17.717466184885296,
                55.20238149065865
              ],
              [
                17.640241985380204,
                55.20019201615797
              ],
              [
                17.5637866232653,
                55.19364539660566
              ],
              [
                17.488860525728573,
                55.18280680492016
              ],
              [
                17.416207422821746,
                55.167784069731255
              ],
              [
                17.346546303915847,
                55.1487264983093
              ],
              [
                17.280563731512547,
                55.12582325289752
              ],
              [
                17.21890661748156,
                55.09930130392386
              ],
              [
                17.162175555537324,
                55.06942298923732
              ],
              [
                17.1109187906186,
                55.03648321346674
              ],
              [
                17.065626891321283,
                55.000806325745316
              ],
              [
                17.026728176214924,
                54.96274271732354
              ],
              [
                16.994584929304782,
                54.92266518297823
              ],
              [
                16.96949042460995,
                54.88096509162454
              ],
              [
                16.95166676528804,
                54.83804841218533
              ],
              [
                16.941263529347623,
                54.79433164062825
              ],
              [
                16.938357202066857,
                54.75023767322671
              ],
              [
                16.942951365001466,
                54.706191669630925
              ],
              [
                16.954977603049702,
                54.66261694735189
              ],
              [
                16.974297084486498,
                54.61993094687293
              ],
              [
                17.000702764148542,
                54.57854130391435
              ],
              [
                17.033922156942108,
                54.53884206248647
              ],
              [
                17.073620627398682,
                54.501210059365704
              ],
              [
                17.119405140923657,
                54.466001507596864
              ],
              [
                17.170828423446707,
                54.43354880362885
              ],
              [
                17.227393478154355,
                54.404157579783735
              ],
              [
                17.28855841062657,
                54.37810402098074
              ],
              [
                17.35374151677977,
                54.35563246201306
              ],
              [
                17.42232659132072,
                54.33695327922336
              ],
              [
                17.49366841774187,
                54.32224108814437
              ],
              [
                17.56709840406362,
                54.3116332565622
              ],
              [
                17.641930331401813,
                54.30522874050604
              ],
              [
                17.717466184885296,
                54.303087248849984
              ],
              [
                17.793002038368783,
                54.30522874050604
              ],
              [
                17.867833965706975,
                54.3116332565622
              ],
              [
                17.941263952028724,
                54.32224108814437
              ],
              [
                18.012605778449878,
                54.33695327922336
              ],
              [
                18.081190852990826,
                54.35563246201306
              ],
              [
                18.14637395914402,
                54.37810402098074
              ],
              [
                18.20753889161624,
                54.404157579783735
              ],
              [
                18.264103946323885,
                54.43354880362885
              ],
              [
                18.31552722884694,
                54.466001507596864
              ],
              [
                18.361311742371914,
                54.501210059365704
              ],
              [
                18.40101021282849,
                54.53884206248647
              ],
              [
                18.434229605622054,
                54.57854130391435
              ],
              [
                18.4606352852841,
                54.61993094687293
              ],
              [
                18.479954766720894,
                54.66261694735189
              ],
              [
                18.491981004769126,
                54.706191669630925
              ],
              [
                18.49657516770374,
                54.75023767322671
              ],
              [
                18.493668840422973,
                54.79433164062825
              ],
              [
                18.483265604482558,
                54.83804841218533
              ],
              [
                18.46544194516064,
                54.88096509162454
              ],
              [
                18.440347440465814,
                54.92266518297823
              ],
              [
                18.408204193555672,
                54.96274271732354
              ],
              [
                18.369305478449313,
                55.000806325745316
              ],
              [
                18.324013579151995,
                55.03648321346674
              ],
              [
                18.27275681423327,
                55.06942298923732
              ],
              [
                18.216025752289035,
                55.09930130392386
              ],
              [
                18.15436863825805,
                55.12582325289752
              ],
              [
                18.08838606585475,
                55.1487264983093
              ],
              [
                18.018724946948847,
                55.167784069731255
              ],
              [
                17.946071844042024,
                55.18280680492016
              ],
              [
                17.871145746505295,
                55.19364539660566
              ],
              [
                17.79469038439039,
                55.20019201615797
              ],
              [
                17.717466184885296,
                55.20238149065865
              ]
            ]
          ]
          }
        }
      })
	map.addSource('point',{
		type: 'geojson',
		data: {
			type: 'Feature',
			geometry:{
				"coordinates": [
        			17.782554010824015,
          			54.75388038877662
        		],
        		"type": "Point"
      			},
		}
	})
  map.addSource('route', {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {
        "title": '50km' 
      },
      geometry:{
        "coordinates": [
          [
            17.712805,
            54.754221
          ],
          [
            17.683262134240323,
            55.198984300131684
          ]
        ],
        "type": "LineString"
      }
    }
  })

  map.addLayer({
    id: 'route',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'butt',
    },
    paint: {
      'line-color': '#888',
      'line-width': 5,
      "line-dasharray": [1, 1]
    }
  });
  
  map.addLayer({
    "id": "symbols",
    "type": "symbol",
    "source": "route",
    "layout": {
        "symbol-placement": "line",
        "text-font": ["Open Sans Regular"],
        "text-field": '{title}',
        "text-size": 32
    },
    "paint": {}
});


	new mapboxgl.Marker()
		.setLngLat([17.712805, 54.754221])
		.addTo(map)

	map.addLayer({
		id: 'point',
		type: 'symbol',
		source: 'point',
		layout: {
			'icon-image': 'custom-marker',
		}
	});

        map.addLayer({
        id: 'maine',
        type: 'fill',
        source: 'maine',
        layout: {},
        paint: {
          'fill-color': '#6372ff',
          'fill-opacity': 0.5
        }
      });

      map.addLayer({
        id: 'outline',
        type: 'line',
        source: 'maine',
        layout: {},
        paint: {
          'line-color': '#000',
          'line-width': 1
        }
      });    
    });
    
    // Clean up on unmount
    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    
    <div>
      <div className='sidebarStyle'>
        <div>
          Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
        </div>
      </div>
      <div className='map-container' ref={mapContainerRef}/>
    </div>
  );
};

